body {
  font-family: CerebriSans-Book !important;
}
.intl-tel-input {
  display: table-cell !important;

  .flag-container {
    left: 0;
  }

  input[type="text"] {
    padding-left: 36px;
  }
}
#headerback {
  height: 114px;
  background-color: #f5f5f5;
  // position: relative;

  .navbar {
    background: #f5f5f5;
    height: auto;
    width: inherit;
    margin: 0 auto;
    top: 0;

    .container {
      /*margin-top:15px;*/
      /*padding-left: 100px;*/
      /*padding-right: 90px;*/
    }

    .identity_title {
      float: right;
      color: #aaaaaa;
      font-family: CerebriSans-SemiBold;
      font-size: 16px;
      margin-top: 50px;
      padding-right: 60px;
      text-transform: uppercase;
      .fa-lock {
        color: #c1c1c1;
        display: inline-block;
        margin-right: 10px;
        margin-top: 10px;
      }
    }
    .transaction_title {
      float: right;
      color: #aaaaaa;
      font-family: CerebriSans-SemiBold;
      font-size: 16px;
      margin-top: 50px;
      text-transform: uppercase;
      .fa-lock {
        color: #c1c1c1;
        display: inline-block;
        margin-right: 10px;
        margin-top: 10px;
      }
    }
  }
  .navbar-default {
    border: none;

    .navbar-header {
      margin-top: 35px;
      padding-left: 60px;
    }
    .navbar-brand {
      padding: 0;
      margin-left: 0 !important;

      img {
        height: auto !important;
        margin-top: -8px;
      }
    }
  }
}

#footerback {
  padding-top: 40px;
  padding-bottom: 25px;
  border-top: solid 2px #eeeeee;
  font-size: 14px;
  color: #999999;
  position: static;
  width: 100%;
  bottom: 0;

  a {
    color: #999999;
    text-decoration: none;
  }

  .footer-txt {
    padding-left: 55px;
  }
  .footer-link {
    cursor: pointer;
    text-align: right;
    padding-right: 55px;
  }
}

.identity {
  background-color: #fff;
  min-height: 748px;

  .thinform {
    margin-left: 200px;

    .below-continue {
      color: #999999;
      font-size: 14px;
      margin: 18px -20px 0 !important;
      width: 400px;
      text-align: center;
    }
  }
  .col-md-9 {
    width: 70%;
  }

  .pad {
    padding-top: 50px;
    /*padding-bottom:50px;*/
  }

  .pad-thin {
    padding-top: 20px;
  }
  .pad-down {
    padding-bottom: 80px;
  }
  .content {
    margin: 0 auto;

    .sub-title {
      font-family: CerebriSans-SemiBold;
      font-size: 28px;
      color: #0c234c;
    }
    .link {
      text-decoration: none;
      color: #555555;
      &:hover {
        text-decoration: underline;
      }
    }
    .loading {
      text-align: center;
      margin-top: 60px;
      img {
        width: 150px;
      }
      .loading-text {
        color: #28dab6;
        font-size: 16px;
        margin-top: 20px;
      }
    }
    .success {
      font-size: 28px;
      color: #0c234c;
      margin-top: 70px;
    }
    .line {
      background-color: #28dab6;
      height: 3px;
      width: 40px;
      margin: 0 auto;
    }

    p {
      color: #555555;
      font-size: 16px;
      font-family: CerebriSans-Light;
      line-height: 1.52;
    }

    .verify-hint {
      background-color: #f5f5f5;
      font-size: 12px;
      font-family: CerebriSans-Book;
      color: #999999;
      padding: 13px;
      margin-top: 40px;

      .fa-icon {
        display: inline-block;
        margin-right: 6px;
      }
      .tries-left {
        font-family: CerebriSans-SemiBold;
      }
    }
    .identity-progress {
      &.notUScetizen {
        .regular-progress {
          li {
            width: 256px;
          }
        }
      }
      .regular-progress {
        display: block;
      }
      .bs-wizard {
        display: none;
      }

      margin-top: 20px;
      margin-bottom: 45px;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          color: #bbbbbb;
          font-size: 12px;
          text-transform: uppercase;
          font-family: CerebriSans-SemiBold;
          width: 190px;
          padding: 5px;
          border-bottom: 3px solid #f5f5f5;
          margin-right: -4px;
        }
        .active {
          color: #28dab6;
          border-bottom: 3px solid #28dab6;
        }
        .complete {
          color: rgba(40, 218, 182, 0.49);
          border-bottom: 3px solid rgba(40, 218, 182, 0.49);
        }
      }
    }
    .form-block {
      width: 100%;
      margin-top: 20px;
      &.personal {
        margin-top: 20px;
      }
      form {
        width: 100%;

        .form-group {
          label {
            font-weight: normal;
            color: #555555;
            font-size: 16px;
            font-family: CerebriSans-Book;
          }
          .input-error {
            color: #d43f00;
            font-size: 12px;
            position: absolute;
            margin-top: 2px;
            width: 90%;

            .fa-icon {
              margin-right: 5px;
            }
          }
          .input-info {
            color: #999999;
            position: absolute;
            font-size: 11px;
            margin-top: 2px;
          }
          .ssn-info {
            margin-left: 194px;
            fill: #bbbbbb;
            cursor: pointer;
            transition: fill 750ms ease-in-out;
          }
          .ssn-info-popup {
            font-size: 12px;
            position: absolute;
            width: 285px;
            top: -115px;
            left: 78px;
            padding: 20px;
            background-color: #f5f5f5;
            font-family: CerebriSans-SemiBold;
          }
        }
        .form-control {
          color: #999;
          &:focus {
            border-color: #28dab6;
            box-shadow: none;
          }
          &::placeholder {
            color: #d7d7d7;
          }
        }
        .form-control.error {
          border-color: #d43f00;
        }
        .pad10 {
          padding-top: 10px;
        }
        .m10 {
          margin-top: 18px;
        }
        .m15 {
          margin-top: 15px;
        }
        .m45 {
          margin-top: 45px;
        }
        .checkboxes {
          margin-top: 30px;

          .check-option {
            font-size: 16px;
            display: inline-block;
            margin-top: -1px;
          }
          .checkbox2 {
            margin-left: 60px;
            .not {
              font-family: CerebriSans-SemiBold;
            }
          }
          md-checkbox {
            .md-container {
              width: 16px;
              height: 16px;
            }
            .md-icon {
              width: 16px;
              height: 16px;
              border-width: 1px;

              &:after {
                left: 4px;
                top: 0px;
                width: 6px;
                height: 11px;
              }
            }
          }
          md-checkbox.md-checked {
            .md-ink-ripple {
              color: #28dab6;
            }
            .md-icon {
              background-color: #28dab6;
            }
          }
        }
        .line-center {
          background-color: #dddddd;
          height: 2px;
          width: 96%;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .form-group {
          padding-left: 0;
          .optional {
            color: #bbbbbb;
          }
        }
        .form-control {
          height: 45px;
          border-radius: 0;
          width: 360px;
        }

        .btn-continue {
          background: #28dab6;
          color: #fff;
          /*letter-spacing: 1px;*/
          border-radius: 2px;
          transition: 300ms ease-in-out;
          padding: 0 25px;
          margin-top: 15px;
          width: 360px;
          font-family: CerebriSans-SemiBold;
          font-size: 17px;
          height: 45px;

          &:hover {
            background: #01b5a2;
          }
        }
        .pad-btn {
          padding-top: 45px;
          padding-bottom: 100px;
        }
        .generate {
          color: #999999;
          font-size: 14px;
          margin-top: 18px;
          margin-left: 85px;
          cursor: pointer;
        }
        .below-continue {
          color: #999999;
          font-size: 14px;
          margin: 18px 150px 0;
        }
      }
      .verify-form {
        label {
          font-size: 16px;
          font-family: CerebriSans-SemiBold;
          color: #555555;
          font-weight: normal;
        }
        md-radio-group .md-checked .md-ink-ripple {
          color: #28dab6;
        }
        md-radio-button {
          font-size: 16px;
          color: #555555;
          margin-bottom: 10px;

          .md-container {
            width: 14px;
            height: 14px;
          }
          .md-off {
            width: 14px;
            height: 14px;
            border-color: #bbbbbb;
            border-width: 1px;
          }
          .md-on {
            width: 14px;
            height: 14px;
            border-width: 1px;
            background-color: #28dab6;
          }
        }
        md-radio-button.md-checked .md-off {
          border-color: #28dab6;
        }
        md-radio-button.first {
          margin-top: 17px;
        }
      }
    }
  }
  .error-block {
    .content {
      margin-left: -20px;

      .error-hint {
        width: 218px;
        background-color: #d43f00;
        font-size: 12px;
        font-family: CerebriSans-SemiBold;
        color: #fff;
        padding: 7px;
        margin-top: 70px;
        text-transform: uppercase;

        .fa-icon {
          display: inline-block;
          margin-right: 6px;
          margin-left: 5px;
        }
      }
      h1 {
        font-family: CerebriSans-Bold;
        color: #d43f00;
        font-size: 28px;
      }
      p {
        font-size: 16px;
        margin-top: 20px;
        color: #555555;
        font-family: CerebriSans-Book;
      }
      ul {
        color: #555555;
        font-size: 16px;

        li {
          line-height: 35px;
        }
      }
    }
  }
}

.bs-wizard {
  border-bottom: solid 1px #e0e0e0;
  padding: 0 0 10px 0;
}
.bs-wizard > .bs-wizard-step {
  padding: 0;
  position: relative;
}
.bs-wizard > .bs-wizard-step + .bs-wizard-step {
}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
  width: 120px;
  color: #28dab6;
  font-size: 12px;
  margin-bottom: 5px;
  font-family: CerebriSans-Bold;
  text-transform: uppercase;
}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum.last {
  margin-left: -25px;
}
.bs-wizard > .bs-wizard-step .bs-wizard-info {
  color: #999;
  font-size: 14px;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
  position: absolute;
  width: 20px;
  height: 20px;
  display: block;
  background: #28dab6;
  top: 50px;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
}
.bs-wizard > .bs-wizard-step.complete > .bs-wizard-dot {
  background: #91e8d6;
}
.bs-wizard > .bs-wizard-step.complete > .bs-wizard-stepnum {
  visibility: hidden;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-stepnum {
  visibility: hidden;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
  content: " ";
  width: 14px;
  height: 14px;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  left: 8px;
}
.bs-wizard > .bs-wizard-step > .progress {
  position: relative;
  border-radius: 0px;
  height: 5px;
  box-shadow: none;
  margin: 26px 0;
}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
  width: 0px;
  box-shadow: none;
  background: #91e8d6;
}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
  background: #91e8d6;
}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
  width: 50%;
}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
  width: 0%;
}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
  background-color: #f5f5f5;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
  opacity: 0;
}
.bs-wizard > .bs-wizard-step:first-child > .progress {
  left: 50%;
  width: 50%;
}
.bs-wizard > .bs-wizard-step:last-child > .progress {
  width: 50%;
}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
  pointer-events: none;
}

/* Small devices (tablets, up to 768px) */
@media (max-width: 768px) {
  .pricing-item {
    padding-bottom: 150px;
  }
  .identity-progress {
    .regular-progress {
      display: none !important;
    }
    .bs-wizard {
      display: block !important;
    }
  }
  .identity .col-md-9 {
    width: auto;
  }
  .identity .content .form-block form .checkboxes .checkbox2 {
    margin-left: 0;
  }
  .identity_title_text {
    display: none;
  }
  #headerback .navbar-default .navbar-header {
    float: left;
    margin-left: 0px;
    padding-left: 0;
  }
  #headerback .navbar .identity_title {
    font-size: 27px;
    margin-top: 35px;
    padding-right: 0;
  }
  .footer_back_links {
    display: block;
    text-align: center;
  }
  #headerback .navbar-default .navbar-brand img {
    width: 280px;
  }
  .identity .content .form-block form .form-control {
    width: 345px;
  }
  .identity .content .form-block form .btn-continue {
    width: 345px;
  }
  .identity .content .form-block form .line-center {
    width: 99%;
  }
  #footerback .footer-link {
    float: none !important;
    text-align: center;
    clear: both;
    padding-bottom: 20px;
    padding-top: 15px;
    padding-right: 0;
  }
  #user-info-form label {
    margin-top: 10px;
  }
  .identity .thinform {
    margin-left: 0;
  }
  .identity .content .form-block form .form-group {
    padding-right: 0;
  }
  .identity .content .form-block form .form-group .ssn-info {
    margin-left: 0;
    float: right;
    padding-left: 8px;
  }
  .identity .content .form-block form .form-group .ssn-info-popup {
    width: 233px;
    left: 112px;
  }
  .phone-code-label {
    margin-top: 20px;
  }
  .identity .content .form-block .verify-form label {
    margin-top: 20px;
  }
  .verify-form .form-group {
    padding-left: 20%;
  }
  .radio-row {
    margin-left: 20%;
  }
  .identity .error-block .content {
    margin-left: 0;
    padding-left: 25px;
    padding-right: 25px;
  }
  .identity .error-block .content .error-hint {
    width: 98%;
    font-size: 19px;
    margin-top: 30px;
  }
  .identity .pad {
    padding-top: 15px;
  }
  .identity .content .identity-progress {
    margin-bottom: 0;
  }
  .identity {
    min-height: 550px;
  }
  .identity .content .success {
    margin-top: 50px;
  }
  #footerback .footer-txt {
    padding-left: 0;
  }
}

@media screen and (min-width: 768px) {
  #headerback .navbar .container {
    padding-left: 15px !important;
  }
}
