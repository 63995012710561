/* header */
header {
    height: auto;
    background-color: #0c234c;
}

header .site-contact {
    font-size: 13px;
    top: 0;
    padding-top: 10px;
    width: 100%;
    color: #959595;
    background: #334462;
    position: relative;
    height: 40px;
    font-family: CerebriSans-SemiBold;
    /*padding-right:118px;*/
}

header .site-contact .contact-text {
    float: right;
    cursor: pointer;
}

header .site-contact .contact-text .number {
    display: inline-block;
}

header .site-contact .contact-text .separator {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

header .site-contact .contact-text a {
    display: inline-block;
    color: #959595;
    text-decoration: none;
    letter-spacing: 1px;
}

header .site-contact .contact-text a:hover {
    color: #cfcfcf;
}

header .navbar {
    background: #0c234c;
    height: 85px;
    width: inherit;
    margin: 0 auto;
    top: 40px;
}

header .navbar .container {
    margin-top: 15px;
    /*padding-left: 100px;*/
    /*padding-right: 90px;*/
}

header .header-top {
    top: 0 !important;
}

header .navbar-default .navbar-brand {
    padding: 0;
}

header .navbar-default .navbar-brand img {
    height: 74px;
    margin-top: -8px;
}

header .navbar-default .navbar-toggle {
    margin-top: 15px;
}

header .navbar-default .navbar-collapse {
    background: #0c234c;
    border-color: #0c234c;
    /*padding-right: 58px; */
    padding-top: 10px;
}

header .navbar-default .navbar-collapse .nav>li>a {
    padding: 8px 15px;
}

header .navbar-default .navbar-collapse .navbar-nav>li {
    cursor: pointer;
}

header .navbar-default .navbar-collapse .navbar-nav>li.underline {
    margin-right: 10px;
}

header .navbar-default .navbar-collapse .navbar-nav>li.underline:after {
    position: absolute;
    bottom: -1px;
    width: 70%;
    left: 15%;
    background-color: #28dab6;
    height: 1px;
    display: block;
    z-index: 999999;
}

header .navbar-default .navbar-collapse .navbar-nav>li.underline:hover:after {
    content: "";
}

header .navbar-default .navbar-collapse .navbar-nav>li>a {
    color: #b8bcc4;
    font-family: CerebriSans-Light;
    font-size: 18px;
    /*&:hover{
                          border-bottom: 0.1em solid #28dab6;
                      }*/
}

header .navbar-default .navbar-collapse .navbar-nav>.active>a,
header .navbar-default .navbar-collapse .navbar-default .navbar-nav>.active>a:hover,
header .navbar-default .navbar-collapse .navbar-default .navbar-nav>.active>a:focus {
    color: #666699;
    background: #0c234c;
}

header .navbar-default .navbar-collapse .navbar-nav>li>a:hover {
    color: #fff;
    background: #0c234c;
}

header .navbar-default .navbar-collapse .account-btn {
    margin-top: -7px;
}

header .navbar-default .navbar-collapse .account-btn .btn-register {
    background: orange;
    color: #fff;
    font-size: 16px;
    font-family: CerebriSans-SemiBold;
    box-shadow: 0px 3px 4px -2px #333;
    transition: 300ms ease-in-out;
    padding: 6px 25px;
    vertical-align: middle;
}

header .navbar-default .navbar-collapse .account-btn .btn-register:hover {
    background-color: lightsalmon;
}

.header-links-box {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media screen and (max-width: 550px) {
    .header-links-box {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (max-width: 667px) {
    .site-contact {
        text-align: center;
        margin: 0 auto;
        padding-right: 0 !important;
        /*letter-spacing: 1.5px;*/
    }

    .registraion-form .form form .fa-google {
        margin-top: 14px;
        font-size: 1.5em;
        margin-left: 18px;
    }

    .steps .btn-how {
        padding: 0;
    }

    .contact-text {
        float: none !important;
        margin-right: 0 !important;
    }

    .navbar .container {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .navbar-nav>li.underline {
        margin-right: 10px;
    }

    .navbar-nav>li.underline:after {
        position: absolute;
        bottom: -1px;
        width: 70%;
        left: 15%;
        background-color: #28dab6;
        height: 1px;
        display: block;
        z-index: 999999;
    }

    .navbar-nav>li.underline:hover:after {
        content: none !important;
    }

    .left-content {
        text-align: center;
    }

    .registraion-form .left-content .line {
        margin: 20px auto;
    }

    .registraion-form .left-content {
        padding-left: 0 !important;
    }

    .seven-cols img {
        width: 65%;
        padding-bottom: 20px;
    }

    footer {
        /*.last{
              padding-right:0;
          }*/
    }

    footer .pull-left {
        float: none !important;
    }

    footer .pull-right {
        float: none !important;
    }

    footer .icon img {
        text-align: center;
        margin: 0 auto;
    }

    footer ul {
        text-align: center;
        margin: 0 auto;
    }

    footer .first {
        padding-left: 0;
    }

    footer .footer-bottom .social {
        padding-top: 40px;
        padding-right: 0 !important;
    }

    footer .footer-bottom .social ul {
        float: none !important;
    }

    footer .footer-bottom .footer-txt {
        text-align: center;
    }

    .about .top p {
        width: 100% !important;
    }

    .pad-sml {
        padding-bottom: 30px;
    }

    .registraion-form .form form {
        width: 370px;
        float: none;
        margin: 0 auto;
    }
}

.btn-register {
    background: orange;
    color: #fff;
    border-radius: 2px;
    transition: 300ms ease-in-out;
    padding: 0 25px;
    width: 100%;
    font-family: CerebriSans-SemiBold;
    font-size: 17px;
    min-height: 36px;
    height: auto;

    &:hover {
        background: lightsalmon;
    }
}