.how-steps-step1 {
    padding-top: 20px;
    padding-bottom: 40px;
    background-color: #f8f8f8;

    .pad {
        padding-top: 40px;
    }

    .sub {
        display: block;
        color: #959595;
        font-size: 16px;
        text-transform: uppercase;
        font-family: CerebriSans-SemiBold;
    }

    h2 {
        color: #2f2f2f;
        font-size: 30px;
        font-family: CerebriSans-SemiBold;
        margin-top: 5px;
    }

    p {
        color: #959595;
        font-size: 17px;
        padding-top: 15px;
        width: 60%;
        margin: 0 auto;
    }

    .step1 {
        margin-top: 20px;

        .left-p1 {
            color: #959595;
            font-size: 18px;
            padding-top: 31px;
            width: 82%;
            float: right;
            margin-right: 20px;
            display: inline-block;
            padding-bottom: 40px;

            h3 {
                color: #2f2f2f;
                font-family: CerebriSans-SemiBold;
            }

            p {
                width: 100%;
            }
        }

        .right-p {
            color: #959595;
            font-size: 18px;
            padding-top: 90px;
            width: 82%;
            float: right;
            margin-right: 20px;
            display: inline-block;
            padding-bottom: 40px;

            h3 {
                color: #2f2f2f;
                font-family: CerebriSans-SemiBold;
            }

            p {
                width: 100%;
            }
        }

        .left-p2 {
            color: #959595;
            font-size: 18px;
            padding-top: 20px;
            width: 82%;
            float: right;
            margin-right: 20px;
            display: inline-block;
            padding-bottom: 40px;

            h3 {
                color: #2f2f2f;
                font-family: CerebriSans-SemiBold;
            }

            p {
                width: 100%;
            }
        }

        img {
            width: 100%
        }
    }
}