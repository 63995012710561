.contact {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5%;
    margin-top: 25px;
    padding-bottom: 70px;

    form {
        max-width: 500px;
        width: 100%;

        h4 {
            font-family: CerebriSans-Bold;
            font-size: 22px;
        }

        button {
            background: #28dab6;
            color: #fff;
            border-radius: 2px;
            transition: 300ms ease-in-out;
            padding: 0 25px;
            margin-top: 15px;
            width: 100%;
            font-family: CerebriSans-Bold;
            font-size: 17px;
            height: 45px;
            margin-bottom: 15px;
        }
    }

    &-box {
        padding: 40px;
        width: 310px;
        height: 310px;
        background-color: #f5f5f5;
        color: #6d6d6d;

        .box {
            h4 {
                color: #353535;
                font-family: CerebriSans-Bold;
                font-size: 17px;
            }

            .social {
                height: 45px;
                margin-top: 20px;

                ul {
                    display: contents;

                    a {
                        margin-right: 15px;

                        svg {
                            color: #bbbbbb;

                            &:hover {
                                color: #28dab6;
                            }
                        }

                    }
                }
            }

            .address {
                padding-top: 20px;
            }

            .number,
            .mail {
                font-size: 16px;

                a {
                    text-decoration: none;
                    color: #6d6d6d;
                }
            }
        }
    }
}

.captcha {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}