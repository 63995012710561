.btn-how {
    background: #fff;
    color: #28dab6;
    border: 2px solid #93ecda;
    transition: 300ms ease-in-out;
    padding: 0 25px;
    margin-top: 15px;
    width: 170px;
    font-family: CerebriSans-SemiBold;
    font-size: 15px;
    height: 45px;
    border-radius: 2px;

    &:hover {
        border: 2px solid #28dab6;
        color: #01b5a2;
    }
}

.registraion-form {
    background: url("../../../public/assets/images/hero-background.jpg") no-repeat;
    background-size: cover;
    position: relative;
    z-index: 99;

    .container {
        min-height: 750px;
    }

    .left-content {
        padding-left: 40px;
        padding-top: 80px;

        h1 {
            color: #0c234c;
            font-size: 42px;
            font-family: "CerebriSans-SemiBold";
        }

        .line {
            background-color: #28dab6;
            height: 2px;
            width: 40px;
            margin-bottom: 20px;
            margin-top: 20px;
        }

        .down {
            font-family: "CerebriSans-Light";
            color: #2f2f2f;
            font-size: 24px;
        }

        .video-image {
            margin-top: 30px;

            img {
                width: 400px;
                cursor: pointer;
            }
        }
    }

    .form {
        padding-top: 85px;
        padding-right: 30px;
        padding-bottom: 75px;

        form {
            box-shadow: 0px 2px 25px -9px #333;
            padding: 17px 0 30px;
            border-radius: 5px;
            margin-top: -2%;
            background: #fff;
            padding-right: 40px;
            padding-left: 40px;
            padding: 28px 30px 46px;
            width: 400px;
            float: right;

            .title {
                text-align: center;
                color: #353535;
                font-family: CerebriSans-SemiBold;
                font-size: 24px;
                margin-top: 10px;
                margin-bottom: 5px;
                font-weight: normal;

                label {
                    font-weight: normal;
                }
            }

            .pad {
                padding: 8px;
            }

            .form-control {
                box-shadow: none;
                background: #f5f5f5;
                height: 45px;
                border-radius: 2px;
                font-size: 16px;
                font-family: CerebriSans-Regular;
            }

            .btn-register {
                background: orange;
                color: #fff;
                /*letter-spacing: 1px;*/
                border-radius: 2px;
                transition: 300ms ease-in-out;
                padding: 0 25px;
                margin-top: 15px;
                width: 100%;
                font-family: CerebriSans-SemiBold;
                font-size: 17px;
                height: 45px;

                &:hover {
                    background: lightsalmon;
                }
            }

            .or-line {
                margin-top: 10px;
            }

            h2 {
                width: 100%;
                text-align: center;
                border-bottom: 1px solid #e9e9e9;
                line-height: 0.1em;
                margin: 10px 0 20px;
                color: #e9e9e9;
                font-size: 14px;

                span {
                    background: #fff;
                    padding: 0 12px;
                }
            }

            .fa-google {
                display: block;
                margin-top: 14px;
                position: absolute;
                font-size: 1.5em;
                margin-left: 20px;
                color: #c8c8c8;
            }

            .btn-google {
                background: #fff;
                color: #848484;
                /*letter-spacing: 1px;    				*/
                transition: 300ms ease-in-out;
                padding: 11px 25px;
                width: 100%;
                font-size: 15px;
                height: 45px;
                border: 1px solid #e9e9e9;
                font-family: CerebriSans-Light;
                border-radius: 2px;

                &:hover {
                    color: #848484;
                    background: #f5f5f5;
                }
            }
        }
    }
}

.steps {
    padding-bottom: 50px;
    background-color: #fff;

    .pad {
        padding-top: 50px;
        padding-bottom: 20px;

        .txt {
            font-family: CerebriSans-SemiBold;
            font-size: 24px;
        }
    }

    .pad2 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    h3 {
        font-family: CerebriSans-SemiBold;
        font-size: 20px;
    }

    .space20 {
        margin-top: 20px;
    }

    .middle {
        padding-top: 3px;

        h3 {
            margin-top: 17px;
        }
    }

    p {
        font-family: CerebriSans-Light;
        font-size: 17px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .btn-how {
        background: #fff;
        color: #28dab6;
        border: 2px solid #93ecda;
        transition: 300ms ease-in-out;
        padding: 0 25px;
        margin-top: 15px;
        width: 170px;
        font-family: CerebriSans-SemiBold;
        font-size: 15px;
        height: 45px;
        border-radius: 2px;

        &:hover {
            border: 2px solid #28dab6;
            color: #01b5a2;
        }
    }
}

.companies {
    padding-top: 20px;
    background-color: #f8f8f8;
    padding-bottom: 55px;

    .pad {
        padding-top: 50px;
        padding-bottom: 20px;
    }

    .pad {
        padding-top: 30px;
        padding-bottom: 20px;
    }

    h2 {
        font-family: CerebriSans-SemiBold;
        font-size: 24px;
    }
}

.seven-cols {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}


.customers {
    padding-top: 50px;
    padding-bottom: 70px;
    background-color: #fff;

    .container {
        min-height: 300px;
    }

    .pad {
        padding-top: 50px;
        padding-bottom: 20px;
    }

    h2 {
        font-family: CerebriSans-SemiBold;
        font-size: 24px;
    }

    .quote {
        margin-top: 20px;

        p {
            font-family: CerebriSans-Light;
            color: #747474;
            /* padding: 35px; */
            font-size: 19px;
            /* padding-right: 50px; */
            /* padding-left: 50px; */
            width: 75%;
            text-align: center;
            margin: 0 auto;

            span {
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-family: CerebriSans-SemiBold;
            }
        }
    }
}