.glossary {
    padding-bottom: 60px;

    &__first {
        margin-top: 40px;
    }

    .item {
        width: 100%;
        display: inline-block;
    }

    .term {
        font-size: 16px;
        font-family: CerebriSans-Bold;
        color: #555555;
        float: left;
    }

    .info {
        color: #555555;
        font-size: 16px;
        font-family: CerebriSans-Light;
        float: right;
        width: 511px;
    }

    .line-center {
        background-color: #dddddd;
        height: 1px;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}