.security-list-textarea {
  width: 100% !important;
  height: 120px !important;
  border-radius: 0 !important;
  resize: none !important;

  &:focus {
    border-color: #28dab6;
    box-shadow: none;
  }
}
