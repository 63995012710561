.join {
  .logo {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 90px;

    img {
      width: 300px;
    }
  }

  .form {
    padding: 50px;
    max-width: 500px !important;

    form {
      box-shadow: 0px 2px 25px -9px #333;
      padding: 17px 0 30px;
      border-radius: 5px;
      margin-top: -2%;
      background: #fff;
      padding-right: 40px;
      padding-left: 40px;

      label {
        font-weight: normal;
      }
      .fa-lock {
        color: #c1c1c1;
        display: inline-block;
        margin-right: 10px;
      }
      .title {
        text-align: center;
        color: #353535;
        font-family: CerebriSans-SemiBold;
        font-size: 24px;
        margin-top: 10px;
      }
      .pad {
        padding: 8px;
      }

      .form-control {
        box-shadow: none;
        background: #f5f5f5;
        height: 45px;
        border-radius: 2px;
        font-size: 16px;
      }

      .btn-register {
        background: orange;
        color: #fff;
        /*letter-spacing: 1px;*/
        border-radius: 2px;
        transition: 300ms ease-in-out;
        padding: 0 25px;
        margin-top: 15px;
        width: 100%;
        font-family: CerebriSans-SemiBold;
        font-size: 17px;
        height: 45px;

        &:hover {
          background: lightsalmon;
        }
      }

      .or-line {
        margin-top: 10px;
      }

      h2 {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #e9e9e9;
        line-height: 0.1em;
        margin: 10px 0 20px;
        color: #e9e9e9;
        font-size: 14px;

        span {
          background: #fff;
          padding: 0 12px;
        }
      }

      .fa-google {
        display: block;
        margin-top: 14px;
        position: absolute;
        font-size: 1.5em;
        margin-left: 10px;
        color: #c8c8c8;
      }

      .btn-google {
        background: #fff;
        color: #848484;
        transition: 300ms ease-in-out;
        padding: 11px 25px;
        width: 100%;
        font-size: 15px;
        height: 45px;
        border: 1px solid #e9e9e9;
        font-family: CerebriSans-Light;
        border-radius: 2px;

        &:hover {
          color: #848484;
          background: #f5f5f5;
        }
      }
    }

    .already {
      font-size: 16px;
      margin-top: 30px;

      a {
        text-decoration: none;

        span {
          color: #28dab6;
        }
      }
    }
    .footer-txt {
      color: #9b9b9b;
      font-size: 12px;
      text-align: center;
      margin-top: 120px;

      a {
        color: #9b9b9b;
        text-decoration: none;
      }
    }
  }
}
