@media screen and (min-width: 200px) {
    .partner-with-us {
        color: #FFFFFF;
        height: 300px;
        text-align: center;

        background:
            linear-gradient(rgba(114, 124, 142, 0.8),
                rgba(114, 124, 142, 0.8)),
            url('../../../public/assets/images/partners-banner-bg.jpg') -340px center no-repeat;
        background-size: cover;

        &__description {
            padding-top: 73.5px;

            .text {
                h3 {
                    font-size: 30px;
                    margin-top: 0;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 18px;
                    margin-bottom: 0;

                    a {
                        color: #37D9B6;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 460px) {
    .partner-with-us {
        color: #FFFFFF;
        height: 300px;
        text-align: left;
        background: #727C8E url('../../../public/assets/images/partners-banner-bg.jpg') right no-repeat;

        .description {
            padding-top: 98.5px;

            .text {
                padding-left: 53px;

                h3 {
                    font-size: 30px;
                    margin-top: 0;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 18px;
                    margin-bottom: 0;

                    a {
                        color: #37D9B6;
                    }
                }
            }
        }
    }
}