@font-face {
  font-family: "CerebriSans-BoldItalic";
  src: url("/fonts/353ECC_0_0.eot");
  src: url("/fonts/353ECC_0_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_0_0.woff2") format("woff2"),
    url("/fonts/353ECC_0_0.woff") format("woff"),
    url("/fonts/353ECC_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-Bold";
  src: url("/fonts/353ECC_1_0.eot");
  src: url("/fonts/353ECC_1_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_1_0.woff2") format("woff2"),
    url("/fonts/353ECC_1_0.woff") format("woff"),
    url("/fonts/353ECC_1_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-Book";
  src: url("/fonts/353ECC_2_0.eot");
  src: url("/fonts/353ECC_2_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_2_0.woff2") format("woff2"),
    url("/fonts/353ECC_2_0.woff") format("woff"),
    url("/fonts/353ECC_2_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-BookItalic";
  src: url("/fonts/353ECC_3_0.eot");
  src: url("/fonts/353ECC_3_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_3_0.woff2") format("woff2"),
    url("/fonts/353ECC_3_0.woff") format("woff"),
    url("/fonts/353ECC_3_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-ExtraBold";
  src: url("/fonts/353ECC_4_0.eot");
  src: url("/fonts/353ECC_4_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_4_0.woff2") format("woff2"),
    url("/fonts/353ECC_4_0.woff") format("woff"),
    url("/fonts/353ECC_4_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-ExtraBoldItalic";
  src: url("/fonts/353ECC_5_0.eot");
  src: url("/fonts/353ECC_5_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_5_0.woff2") format("woff2"),
    url("/fonts/353ECC_5_0.woff") format("woff"),
    url("/fonts/353ECC_5_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-Heavy";
  src: url("/fonts/353ECC_6_0.eot");
  src: url("/fonts/353ECC_6_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_6_0.woff2") format("woff2"),
    url("/fonts/353ECC_6_0.woff") format("woff"),
    url("/fonts/353ECC_6_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-Italic";
  src: url("/fonts/353ECC_7_0.eot");
  src: url("/fonts/353ECC_7_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_7_0.woff2") format("woff2"),
    url("/fonts/353ECC_7_0.woff") format("woff"),
    url("/fonts/353ECC_7_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-LightItalic";
  src: url("/fonts/353ECC_8_0.eot");
  src: url("/fonts/353ECC_8_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_8_0.woff2") format("woff2"),
    url("/fonts/353ECC_8_0.woff") format("woff"),
    url("/fonts/353ECC_8_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-HeavyItalic";
  src: url("/fonts/353ECC_9_0.eot");
  src: url("/fonts/353ECC_9_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_9_0.woff2") format("woff2"),
    url("/fonts/353ECC_9_0.woff") format("woff"),
    url("/fonts/353ECC_9_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-Light";
  src: url("/fonts/353ECC_A_0.eot");
  src: url("/fonts/353ECC_A_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_A_0.woff2") format("woff2"),
    url("/fonts/353ECC_A_0.woff") format("woff"),
    url("/fonts/353ECC_A_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-Medium";
  src: url("/fonts/353ECC_B_0.eot");
  src: url("/fonts/353ECC_B_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_B_0.woff2") format("woff2"),
    url("/fonts/353ECC_B_0.woff") format("woff"),
    url("/fonts/353ECC_B_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-MediumItalic";
  src: url("/fonts/353ECC_C_0.eot");
  src: url("/fonts/353ECC_C_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_C_0.woff2") format("woff2"),
    url("/fonts/353ECC_C_0.woff") format("woff"),
    url("/fonts/353ECC_C_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-Regular";
  src: url("/fonts/353ECC_D_0.eot");
  src: url("/fonts/353ECC_D_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_D_0.woff2") format("woff2"),
    url("/fonts/353ECC_D_0.woff") format("woff"),
    url("/fonts/353ECC_D_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-SemiBoldItalic";
  src: url("/fonts/353ECC_E_0.eot");
  src: url("/fonts/353ECC_E_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_E_0.woff2") format("woff2"),
    url("/fonts/353ECC_E_0.woff") format("woff"),
    url("/fonts/353ECC_E_0.ttf") format("truetype");
}

@font-face {
  font-family: "CerebriSans-SemiBold";
  src: url("/fonts/353ECC_F_0.eot");
  src: url("/fonts/353ECC_F_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/353ECC_F_0.woff2") format("woff2"),
    url("/fonts/353ECC_F_0.woff") format("woff"),
    url("/fonts/353ECC_F_0.ttf") format("truetype");
}

.input-error {
  color: #d43f00 !important;
  font-size: 12px;
  margin-top: 2px;

  .fa-icon {
    margin-right: 5px;
  }
}

.font-size-75 {
  font-size: 0.75rem !important;
  color: #999;
}