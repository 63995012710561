.account {
  background-color: #fff;

  .row {
    padding-top: 35px;
    padding-bottom: 225px;
  }
  .overview-box {
    padding: 40px;
    color: #6d6d6d;

    .box {
      background-color: #f5f5f5;
      padding: 30px;
      line-height: 27px;

      .arrow-icon {
        color: #bbbbbb;
        font-size: 20px;
      }
      .title {
        color: #959595;
        font-size: 16px;
        cursor: auto;
      }

      h4 {
        cursor: pointer;
        color: #353535;
        font-family: CerebriSans-SemiBold;
        font-size: 16px;
        padding-top: 10px;

        &.active {
          color: #999999;
        }
      }
      .line-center {
        background-color: #dddddd;
        height: 2px;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px;
      }
      .info-block {
        color: #555555;
        font-size: 14px;

        .title {
          font-size: 14px;
          padding-bottom: 10px;
          color: #555555;
        }
        .info {
          font-size: 14px;
          line-height: 20px;
          color: #555555;
        }
      }
    }
  }
  .account-content {
    padding-top: 25px;

    .warn-hint {
      background-color: #f5e4da;
      color: #d43f00;
      padding: 8px;
      font-size: 12px;
    }

    h2 {
      color: #0c234c;
      font-size: 18px;
      font-family: CerebriSans-SemiBold;
    }
    .sub-title {
      font-family: CerebriSans-SemiBold;
      font-size: 28px;
      color: #0c234c;
    }
    .no-transactions {
      color: #555555;
      font-size: 15px;
      margin-top: 20px;
    }
    .line-center {
      background-color: #dddddd;
      height: 2px;
      width: 100%;
      margin-top: 25px;
      margin-bottom: 30px;
    }
    .ready {
      color: #555555;
      font-size: 15px;
    }
    .action-button {
      .btn-continue {
        background: #28dab6;
        color: #fff;
        border-radius: 2px;
        transition: 300ms ease-in-out;
        padding: 0 25px;
        margin-top: 30px;
        width: 230px;
        font-family: CerebriSans-SemiBold;
        font-size: 16px;
        height: 45px;

        &:hover {
          background: #01b5a2;
        }
      }
    }
    .transactions-section {
      padding-right: 0;
      padding-left: 0;

      #transactions-table {
        width: 100%;
        margin-top: 45px;

        &.transaction_history {
          margin-top: 10px;
        }
        thead {
          border-bottom: solid 1px #dddddd;
          th {
            font-size: 10px;
            text-transform: uppercase;
            color: #999999;
            font-family: CerebriSans-SemiBold;
            padding-top: 10px;
            padding-bottom: 13px;

            &:last-child {
              text-align: right;
            }
          }
        }
        tbody {
          tr {
            &.complete {
              td {
                color: #999999 !important;
              }
            }
            td {
              color: #555555;
              font-size: 13px;
              font-family: CerebriSans-SemiBold;

              &:last-child {
                text-align: right;
              }

              .btn-resume {
                background-color: #f5f5f5;
                border: solid 1px #bbbbbb;
                color: #999999;
                border-radius: 2px;
                width: 70px;
                font-family: CerebriSans-SemiBold;
                font-size: 11px;
                height: 28px;
                margin-right: 22px;
                &:hover {
                  color: #777777;
                  background-color: #f5f5f5;
                  border: solid 2px #bbbbbb;
                }
              }
              .btn-view-details {
                background-color: #f5f5f5;
                border: solid 1px #bbbbbb;
                color: #999999;
                border-radius: 2px;
                width: 92px;
                font-family: CerebriSans-SemiBold;
                font-size: 11px;
                height: 28px;

                &:hover {
                  color: #777777;
                  background-color: #f5f5f5;
                  border: solid 2px #bbbbbb;
                }
              }
            }
          }
        }
        tr {
          border-bottom: solid 1px #dddddd;
          td {
            color: #555555;
            font-size: 14px;
            padding-top: 10px;
            padding-bottom: 10px;
          }
          .fa-icon {
            color: #bbbbbb;
            font-size: 16px;
            width: 13px;
            height: 17px;
            right: 0;
            position: absolute;
            margin-top: 6px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .account-content {
    &.details {
      .sub-title {
        font-size: 35px;
      }
      .success-update {
        background-color: rgba(40, 218, 182, 0.1);
        font-size: 12px;
        font-family: CerebriSans-Light;
        color: #28dab6;
        padding: 6px;
        margin-top: 22px;
        padding-top: 9px;
        width: 95.5%;

        .check-icon {
          display: inline-block;
          margin-top: 6px;
          margin-left: 8px;
        }
      }
      .form-block {
        form {
          .m10 {
            margin-top: 10px;
          }
          .form-control {
            width: 340px;
            height: 45px;
            border-radius: 0;

            &:focus {
              border-color: #28dab6;
              box-shadow: none;
            }
          }
          .form-control[readonly] {
            background-color: #fff;
            color: #999999;
          }
          .form-group {
            padding-right: 0;
            padding-left: 0;

            .input-error {
              color: #d43f00;
              font-size: 12px;
              position: absolute;
              margin-top: 2px;

              .fa-icon {
                margin-right: 5px;
              }
            }
          }

          .line-center {
            display: -webkit-inline-box;
            width: 95.5%;
            margin-bottom: 10px;
          }
          label {
            margin-top: 10px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .btn-update {
    background: #28dab6;
    color: #fff;
    border-radius: 2px;
    transition: 300ms ease-in-out;
    padding: 0 25px;
    margin-top: 30px;
    width: 230px;
    font-family: CerebriSans-SemiBold;
    font-size: 16px;
    height: 45px;

    &:hover {
      background: #01b5a2;
    }
  }
  .modal-dialog {
    width: 558px;

    .close {
      width: 20px;
      height: 20px;
      color: #acacac;
      font-weight: bold;
      right: 20px;
      margin-top: 20px;
      opacity: 1;
      cursor: pointer;
      position: absolute;
      z-index: 1000000;
    }
    .modal-content {
      width: 515px;
      height: 728px;

      .modal-body {
        width: 360px;
        margin: 0 auto;
        padding: 0;
        padding-top: 55px;

        .title-header {
          color: #555555;
          font-size: 16px;
        }
        .title {
          color: #0c234c;
          font-size: 23.9px;
          font-family: CerebriSans-SemiBold;
          text-align: center;
          padding-bottom: 10px;
        }
        form {
          .form-group {
            &.col-md-12 {
              padding-right: 0;
              padding-left: 0;
            }
            label {
              font-weight: normal;
              color: #6d6d6d;
              font-size: 14px;
            }
            .input-info {
              color: #999999;
              position: absolute;
              font-size: 11px;
              margin-top: 2px;
            }
            .input-error {
              color: #d43f00;
              font-size: 12px;
              position: absolute;
              margin-top: 2px;

              .fa-icon {
                margin-right: 5px;
              }
            }

            .form-control {
              height: 45px;
              border-radius: 0;
              width: 100%;

              &:focus {
                border-color: #28dab6;
                box-shadow: none;
              }
            }

            .form-control.error {
              border-color: #d43f00;
            }
          }
          .btn-ok {
            background: #28dab6;
            color: #fff;
            /*letter-spacing: 1px;*/
            border-radius: 2px;
            transition: 300ms ease-in-out;
            padding: 0 25px;
            margin-top: 15px;
            width: 100%;
            font-family: CerebriSans-SemiBold;
            font-size: 17px;
            height: 45px;

            &:hover {
              background: #01b5a2;
            }
          }
          .btn-cancel {
            background-color: #dddddd;
            color: #fff;
            border-radius: 2px;
            transition: 300ms ease-in-out;
            padding: 0 25px;
            margin-top: 15px;
            width: 100%;
            font-family: CerebriSans-SemiBold;
            font-size: 17px;
            height: 45px;

            &:hover {
              background-color: #bbbbbb;
            }
          }
          .regenerate {
            color: #999999;
            font-size: 14px;
            display: inline-block;
            margin-left: 10px;
            padding-bottom: 4px;
            padding-top: 20px;
          }
          .pad-btn {
            padding-top: 15px;
            padding-bottom: 100px;
          }

          .m20 {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
