.MuiTabs-indicator {
  background-color: #28dab6 !important;
}

.MuiRadio-colorPrimary {
  color: #28dab6 !important;
}

.transaction {
  background-color: #fff;
  min-height: 748px;
  width: 100%;

  .col-md-2 {
    width: 5%;
  }

  .col-md-1 {
    width: 3%;
  }

  .col-md-11 {
    width: 95%;
  }

  .pad {
    padding-top: 50px;
    /*padding-bottom:50px;*/
  }

  .row {
    &.transaction-details {
      padding-left: 60px;
      padding-right: 60px;
      padding-bottom: 50px;
    }
  }

  .transaction-data {
    .col-md-12 {
      padding-right: 0;
      padding-left: 0;
    }

    .back-to-account {
      float: left;
      margin-top: 15px;
      width: 100%;

      a {
        text-decoration: none;
        color: #bbbbbb;
      }

      .arrow-back {
        color: #bbbbbb;
        width: 15px;
        height: 15px;
      }

      color: #bbbbbb;
      font-size: 15px;
      font-family: CerebriSans-Book;
    }

    .line-center {
      clear: both;
      background-color: #dddddd;
      height: 2px;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .content {
      margin-bottom: 20px;

      h1 {
        color: #0c234c;
        font-size: 28px;
        font-family: CerebriSans-Bold;
        float: left;
      }

      .btn-print {
        float: right;
        background-color: #fff;
        border: solid 1px #bbbbbb;
        color: #999999;
        border-radius: 2px;
        width: 110px;
        font-family: CerebriSans-SemiBold;
        font-size: 11px;
        height: 28px;
        margin-top: 20px;

        &:hover {
          color: #777777;
          background-color: #fff;
          border: solid 2px #bbbbbb;
        }
      }

      .m25 {
        margin-top: 25px;
      }

      .m30 {
        margin-top: 30px;
      }

      .data-title {
        font-size: 15px;
        color: #555555;
        font-family: CerebriSans-SemiBold;
      }

      .value {
        font-family: CerebriSans-Book;

        &.address {
          line-height: 23px;
        }
      }

      .col-md-3 {
        padding-right: 0;
        padding-left: 0;
        width: 22%;

        &.last {
          width: 30%;
          float: right;
        }
      }

      .summary {
        font-size: 14px;
        color: #555555;
        line-height: 23px;

        .item {
          height: 23px;

          &.total {
            font-family: CerebriSans-SemiBold;
            margin-top: 5px;
          }

          .info-icon {
            width: 9px;
            height: 9px;
            color: #a6a6a6;
            margin-left: 5px;
            margin-top: -3px;
            cursor: pointer;
          }
        }

        .item-title {
          float: left;

          .label {
            background-color: #e6e6e6;
            text-transform: uppercase;
            width: 75px;
            height: 17px;
            font-size: 12px;
            color: #262626;
            padding: 2px;
            margin-left: 12px;
            padding-left: 5px;
            padding-right: 5px;
          }
        }

        .item-price {
          display: inline-block;
          float: right;
        }

        .line-center {
          clear: both;
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }
    }

    .line-center {
      margin-top: 40px;
      display: -webkit-inline-box;
      margin-bottom: 15px;
    }
  }

  .medallion-information {
    .col-md-12 {
      padding-right: 0;
      padding-left: 0;
    }

    .sub-title {
      font-size: 22px;
      color: #0c234c;
      font-family: CerebriSans-SemiBold;
    }

    #medallion-table {
      width: 100%;
      margin-top: 15px;

      thead {
        border-bottom: solid 1px #dddddd;

        th {
          font-size: 10px;
          text-transform: uppercase;
          color: #999999;
          font-family: CerebriSans-SemiBold;
          padding-top: 10px;
          padding-bottom: 13px;

          .info-icon {
            width: 12px;
            height: 12px;
            display: inline-block;
            margin-left: 5px;
            margin-top: -3px;
          }
        }
      }

      tbody {
        tr {
          &.complete {
            td {
              color: #999999 !important;
            }
          }

          td {
            color: #555555;
            font-size: 14px;
            font-family: CerebriSans-SemiBold;

            .check-icon {
              color: #999999;
            }

            .fa-icon {
              color: #d43f00;
              position: inherit;
              margin-top: 0;
            }
          }
        }
      }

      tr {
        border-bottom: solid 1px #dddddd;

        td {
          color: #555555;
          font-size: 14px;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .fa-icon {
          color: #bbbbbb;
          font-size: 16px;
          width: 13px;
          height: 17px;
          right: 16px;
          position: absolute;
          margin-top: 6px;
          cursor: pointer;
        }
      }
    }
  }

  .documents-information {
    margin-top: 40px;

    .col-md-12 {
      padding-right: 0;
      padding-left: 0;
    }

    .sub-title {
      font-size: 22px;
      color: #0c234c;
      font-family: CerebriSans-SemiBold;
    }

    #documents-table {
      width: 100%;
      margin-top: 15px;

      thead {
        border-bottom: solid 1px #dddddd;

        th {
          font-size: 10px;
          text-transform: uppercase;
          color: #999999;
          font-family: CerebriSans-SemiBold;
          padding-top: 10px;
          padding-bottom: 13px;

          &:last-child {
            text-align: right;
          }

          .refresh {
            width: 14px;
            height: 14px;
            margin-left: 3px;
            margin-top: -3px;
          }
        }
      }

      tbody {
        tr {

          /*&.complete{
							td{
								color: #999999 !important;
							}
						}*/
          td {
            color: #555555;
            font-size: 13px;
            font-family: CerebriSans-SemiBold;

            &.uploaded {
              font-size: 13px;
              color: #999999;
              font-style: italic;
              font-family: CerebriSans-Book;
            }

            &.not-provided {
              color: #d43f00;
              font-size: 13px;
              font-family: CerebriSans-Book;
            }

            .check-icon {
              color: #28dab6;
            }

            .fa-icon {
              color: #d43f00;
              position: inherit;
              margin-top: 0;
            }

            .clear-detail {
              color: #bbbbbb;
            }

            &:last-child {
              text-align: right;
            }

            .btn-resume {
              background-color: #f5f5f5;
              border: solid 1px #bbbbbb;
              color: #999999;
              border-radius: 2px;
              width: 70px;
              font-family: CerebriSans-SemiBold;
              font-size: 11px;
              height: 28px;
              margin-right: 22px;

              &:hover {
                color: #777777;
                background-color: #f5f5f5;
                border: solid 2px #bbbbbb;
              }
            }

            .btn-view-details {
              background-color: #f5f5f5;
              border: solid 1px #bbbbbb;
              color: #999999;
              border-radius: 2px;
              width: 75px;
              font-family: CerebriSans-SemiBold;
              font-size: 11px;
              height: 28px;
              margin-right: 5px;

              &:hover {
                color: #777777;
                background-color: #f5f5f5;
                border: solid 2px #bbbbbb;
              }
            }

            .btn-upload-create {
              background-color: #f5f5f5;
              border: solid 1px #bbbbbb;
              color: #999999;
              border-radius: 2px;
              width: 92px;
              font-family: CerebriSans-SemiBold;
              font-size: 11px;
              height: 28px;

              &:hover {
                color: #777777;
                background-color: #f5f5f5;
                border: solid 2px #bbbbbb;
              }
            }
          }
        }
      }

      tfoot {
        tr {
          border-bottom: none;

          .btn-tfoot {
            background-color: #f5f5f5;
            border: solid 1px #bbbbbb;
            color: #999999;
            border-radius: 2px;
            margin-top: 10px;
            width: 200px;
            font-family: CerebriSans-SemiBold;
            font-size: 11px;
            height: 28px;

            &:hover {
              color: #777777;
              background-color: #f5f5f5;
              border: solid 2px #bbbbbb;
            }

            &.left {
              width: 185px;
            }

            &.right {
              margin-left: 10px;
            }

            &.add-form {
              width: 136px;
            }
          }
        }
      }

      tr {
        border-bottom: solid 1px #dddddd;

        td {
          color: #555555;
          font-size: 14px;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .fa-icon {
          color: #bbbbbb;
          font-size: 16px;
          width: 13px;
          height: 17px;
          right: 16px;
          position: absolute;
          margin-top: 6px;
          cursor: pointer;
        }
      }
    }

    .back-to-account {
      float: left;
      margin-top: 30px;

      a {
        text-decoration: none;
        color: #bbbbbb;
      }

      .arrow-back {
        color: #bbbbbb;
        width: 15px;
        height: 15px;
      }

      color: #bbbbbb;
      font-size: 15px;
      font-family: CerebriSans-Book;
    }

    .action-button {
      float: right;

      .btn-continue {
        background: #28dab6;
        color: #fff;
        border-radius: 2px;
        transition: 300ms ease-in-out;
        padding: 0 25px;
        margin-top: 20px;
        width: 230px;
        font-family: CerebriSans-SemiBold;
        font-size: 16px;
        height: 45px;

        &:hover {
          background: #01b5a2;
        }
      }
    }

    .line-center {
      clear: both;
      background-color: #dddddd;
      height: 2px;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  .content {
    margin: 0 auto;

    .sub-title {
      font-family: CerebriSans-SemiBold;
      font-size: 28px;
      color: #0c234c;
      margin-bottom: 20px;
    }

    .line-center {
      background-color: #dddddd;
      height: 1px;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 15px;
    }

    p {
      color: #555555;
      font-size: 16px;
      font-family: CerebriSans-Light;
    }

    .transaction-progress {
      .regular-progress {
        display: block;
      }

      margin-top: 20px;
      margin-bottom: 45px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          color: #bbbbbb;
          font-size: 12px;
          text-transform: uppercase;
          font-family: CerebriSans-SemiBold;
          width: 270px;
          padding: 5px;
          border-bottom: 3px solid #f5f5f5;
          margin-right: -3px;
        }

        .active {
          color: #28dab6;
          border-bottom: 3px solid #28dab6;
        }

        .complete {
          color: rgba(40, 218, 182, 0.49);
          border-bottom: 3px solid rgba(40, 218, 182, 0.49);
        }
      }
    }

    .form-block {
      width: 100%;

      form {
        width: 100%;

        .form-group {
          .input-error {
            color: #d43f00;
            font-size: 12px;
            position: absolute;
            margin-top: 2px;

            .fa-icon {
              margin-right: 5px;
            }
          }

          .input-info {
            color: #999999;
            position: absolute;
            font-size: 11px;
            margin-top: 2px;
          }
        }

        .mb80 {
          margin-bottom: 80px;
        }

        .form-control {
          &:focus {
            border-color: #28dab6;
            box-shadow: none;
          }
        }

        .form-control.error {
          border-color: #d43f00;
        }

        .pad10 {
          padding-top: 10px;
        }

        .m10 {
          margin-top: 10px;
        }

        .m20 {
          margin-top: 20px;
        }

        .m15 {
          margin-top: 15px;
        }

        .m45 {
          margin-top: 45px;
        }

        .line-center {
          background-color: #dddddd;
          height: 2px;
          width: 100%;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .form-group {
          padding-left: 0;

          .optional {
            color: #bbbbbb;
          }
        }

        .form-control {
          height: 45px;
          border-radius: 0;
          width: 360px;
        }

        .btn-continue {
          background: #28dab6;
          color: #fff;
          /*letter-spacing: 1px;*/
          border-radius: 2px;
          transition: 300ms ease-in-out;
          padding: 0 25px;
          margin-top: 15px;
          width: 360px;
          font-family: CerebriSans-SemiBold;
          font-size: 17px;
          height: 45px;
          margin-left: 0;

          &:hover {
            background: #01b5a2;
          }
        }

        .pad-btn {
          padding-top: 45px;
          padding-bottom: 100px;
        }
      }

      .ownership_form {
        label {
          font-size: 16px;
          font-family: CerebriSans-SemiBold;
          color: #555555;
          font-weight: normal;
          display: block;
        }

        .input-error {
          margin-top: -5px !important;
          margin-left: 2px;
        }

        md-radio-group .md-checked .md-ink-ripple {
          color: #28dab6;
        }

        md-radio-button {
          font-size: 16px;
          color: #555555;
          margin-bottom: 5px;

          .md-container {
            width: 14px;
            height: 14px;
          }

          .md-off {
            width: 14px;
            height: 14px;
            border-color: #bbbbbb;
            border-width: 1px;
          }

          .md-on {
            width: 14px;
            height: 14px;
            border-width: 1px;
            background-color: #28dab6;
          }
        }

        md-radio-button.md-checked .md-off {
          border-color: #28dab6;
        }

        md-radio-button.first {
          margin-top: 17px;
        }

        md-radio-button.error .md-off {
          border-color: #d43f00;
        }
      }
    }

    .box-left {
      width: 660px;

      h4 {
        font-size: 15px;
        font-family: CerebriSans-SemiBold;
        color: #262626;
      }

      .add_new_form {
        height: 141px;
        background-color: #f5f5f5;
        padding-left: 28px;
        padding-top: 23px;
        border: solid 3px #dddddd;
        margin-top: 38px;

        .form-title {
          font-size: 12px;
          text-transform: uppercase;
          color: #999999;
          font-family: CerebriSans-SemiBold;
          padding-bottom: 16px;
        }

        .m-left {
          margin-left: 15px;
        }

        label {
          font-weight: 400;
        }

        input {
          width: 155px;
          height: 30px;
        }

        .btn-add {
          background: #28dab6;
          color: #fff;
          /*letter-spacing: 1px;*/
          border-radius: 2px;
          transition: 300ms ease-in-out;
          padding: 0 20px;
          margin-top: 25px;
          width: 75px;
          font-family: CerebriSans-SemiBold;
          font-size: 14px;
          height: 30px;

          &:hover {
            background: #01b5a2;
          }
        }
      }

      .details-section {
        padding-right: 0;
        padding-left: 0;

        #details-table {
          width: 100%;
          margin-top: 45px;

          thead {
            border-bottom: solid 1px #dddddd;

            th {
              font-size: 10px;
              text-transform: uppercase;
              color: #999999;
              font-family: CerebriSans-SemiBold;
              padding-top: 10px;
              padding-bottom: 13px;
              font-weight: normal;
            }
          }

          tfoot {
            tr {
              border-bottom: none;
            }
          }

          tr {
            border-bottom: solid 1px #dddddd;

            td {
              color: #555555;
              font-size: 14px;
              padding-top: 10px;
              padding-bottom: 10px;
            }

            .clear-detail {
              color: #bbbbbb;
            }
          }
        }
      }

      .action-buttons {
        padding-right: 0;
        padding-left: 0;
        padding-top: 40px;
        padding-bottom: 70px;
      }

      .btn-continue {
        background: #28dab6;
        color: #fff;
        /*letter-spacing: 1px;*/
        border-radius: 2px;
        transition: 300ms ease-in-out;
        padding: 0 25px;
        margin-top: 15px;
        width: 310px;
        font-family: CerebriSans-SemiBold;
        font-size: 17px;
        height: 45px;
        margin-left: 35px;

        &:hover {
          background: #01b5a2;
        }
      }

      .btn-save {
        background-color: #dddddd;
        color: #fff;
        border-radius: 2px;
        transition: 300ms ease-in-out;
        padding: 0 25px;
        margin-top: 15px;
        width: 310px;
        font-family: CerebriSans-SemiBold;
        font-size: 17px;
        height: 45px;

        &:hover {
          background-color: #bbbbbb;
        }
      }

      .securities_section {
        .line-center {
          height: 1px;
          margin-top: 30px;
        }
      }

      .securities-form {
        padding-left: 0;
        padding-right: 0;
        padding-top: 10px;

        .securities-label {
          display: inline-block;
          font-size: 14px;
          color: #262626;
        }

        .securities-info {
          display: inline-block;
          float: right;

          .securities-info-icon {
            fill: #bbbbbb;
            cursor: pointer;
            transition: fill 750ms ease-in-out;
            width: 16px;
            height: 16px;
          }
        }

        .securities-info-popup {
          font-size: 9px;
          position: absolute;
          width: 150px;
          top: -21px;
          right: -160px;
          padding: 15px;
          background-color: #e9e9e9;
          font-family: CerebriSans-SemiBold;

          &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 8px solid #e9e9e9;
            left: -8px;
            top: 35px;
          }
        }

        .securities-list-text {
          width: 100%;
          margin-top: 5px;

          .securities-list {
            width: 100%;
            height: 120px;
            border-radius: 0;
            resize: none;

            &:focus {
              border-color: #28dab6;
              box-shadow: none;
            }
          }

          .note {
            font-size: 12px;
            color: #262626;
            margin-top: 4px;
            display: block;
          }
        }

        .account-value-input {
          margin-top: 45px;

          label {
            font-weight: normal;
          }

          input {
            border-radius: 0;

            &:focus {
              border-color: #28dab6;
              box-shadow: none;
            }
          }
        }

        .form-control.error {
          border-color: #d43f00;
        }

        .input-error {
          color: #d43f00;
          font-size: 12px;
          position: relative;
          margin-top: 2px;
        }
      }

      .proceed-to-checkout {
        height: 91px;
        background-color: #f7f7f7;
        padding-left: 20px;
        padding-top: 16px;
        border: solid 1px #dddddd;
        margin-top: 38px;
        color: #555555;

        .left-txt {
          width: 412px;

          span {
            font-size: 13px;
            font-family: CerebriSans-SemiBold;
            display: block;
          }
        }

        .btn-block {
          margin-right: 20px;
          width: 160px;

          .btn-proceed {
            background-color: rgba(40, 218, 182, 0.05);
            border: solid 1px #28dab6;
            color: #28dab6;
            border-radius: 2px;
            transition: 300ms ease-in-out;
            padding: 0 15px;
            margin-top: 16px;
            width: 160px;
            font-family: CerebriSans-SemiBold;
            font-size: 13px;
            height: 36px;

            &:hover {
              color: #01b5a2;
              border: solid 2px #28dab6;
            }
          }
        }
      }

      .line-center.proceed {
        margin-top: 40px;
        margin-bottom: 40px;
      }

      .checklist-block {
        h2 {
          color: #0c234c;
          font-size: 18px;
          font-family: CerebriSans-SemiBold;
        }

        p {
          font-size: 15px;
          color: #555555;
        }

        h3 {
          font-size: 13px;
          font-family: CerebriSans-SemiBold;
          color: #555555;
        }

        .documents-section {
          padding-right: 0;
          padding-left: 0;

          #documents-table {
            width: 100%;
            margin-top: 15px;

            thead {
              border-bottom: solid 1px #dddddd;

              th {
                font-size: 10px;
                text-transform: uppercase;
                color: #999999;
                font-family: CerebriSans-SemiBold;
                padding-top: 10px;
                padding-bottom: 13px;
                font-weight: normal;

                &:last-child {
                  text-align: right;
                }

                .refresh {
                  width: 14px;
                  height: 14px;
                  margin-left: 3px;
                  margin-top: -3px;
                }
              }
            }

            tbody {
              tr {
                td {
                  &.uploaded {
                    font-size: 13px;
                    color: #28dab6;
                    font-style: italic;
                  }

                  &.not-provided {
                    color: #999999;
                    font-size: 13px;
                  }

                  &:first-child {
                    font-family: CerebriSans-SemiBold;
                    font-size: 13px;
                    color: #555555;
                  }

                  &:last-child {
                    text-align: right;
                  }

                  .view-file {
                    color: #bbbbbb;
                  }

                  .btn-upload {
                    background-color: #f5f5f5;
                    border: solid 1px #bbbbbb;
                    color: #999999;
                    border-radius: 2px;
                    width: 75px;
                    font-family: CerebriSans-SemiBold;
                    font-size: 11px;
                    height: 28px;

                    &:hover {
                      color: #777777;
                      background-color: #f5f5f5;
                      border: solid 2px #bbbbbb;
                    }
                  }

                  .btn-create {
                    background-color: #f5f5f5;
                    border: solid 1px #bbbbbb;
                    color: #999999;
                    border-radius: 2px;
                    width: 92px;
                    font-family: CerebriSans-SemiBold;
                    font-size: 11px;
                    height: 28px;

                    &:hover {
                      color: #777777;
                      background-color: #f5f5f5;
                      border: solid 2px #bbbbbb;
                    }
                  }
                }
              }
            }

            tfoot {
              tr {
                border-bottom: none;

                .btn-tfoot {
                  background-color: #f5f5f5;
                  border: solid 1px #bbbbbb;
                  color: #999999;
                  border-radius: 2px;
                  margin-top: 10px;
                  width: 200px;
                  font-family: CerebriSans-SemiBold;
                  font-size: 11px;
                  height: 28px;

                  &:hover {
                    color: #777777;
                    background-color: #f5f5f5;
                    border: solid 2px #bbbbbb;
                  }

                  &.left {
                    width: 185px;
                  }

                  &.right {
                    margin-left: 10px;
                  }
                }
              }
            }

            tr {
              border-bottom: solid 1px #dddddd;

              td {
                color: #555555;
                font-size: 14px;
                padding-top: 10px;
                padding-bottom: 10px;
              }

              .clear-detail {
                color: #bbbbbb;
              }
            }
          }
        }

        .under-table {
          font-size: 13px;
          color: #555555;
          padding-top: 40px;
          clear: both;
        }

        .action-buttons {
          padding-top: 20px;
        }
      }

      .shiping_method_select {
        .col-md-12 {
          padding-right: 0;
          padding-left: 0;

          label {
            color: #262626;
            font-size: 14px;
            font-weight: normal;
            padding-bottom: 20px;
            font-family: CerebriSans-Light;
          }

          md-radio-button {
            font-size: 14px;
            color: #262626;
            line-height: 20px;

            .md-container {
              width: 12px;
              height: 12px;
            }

            .md-off {
              width: 12px;
              height: 12px;
              border-color: #bbbbbb;
              border-width: 0.5px;
            }

            .md-on {
              width: 12px;
              height: 12px;
              border-width: 1px;
              background-color: #28dab6;
            }

            &.md-checked {
              .md-off {
                border-color: #28dab6;
              }
            }
          }
        }

        .line-center {
          clear: both;
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }

      .shipping-address {
        md-checkbox.md-checked .md-ink-ripple {
          color: #28dab6;
        }

        md-checkbox .md-container {
          width: 12px;
          height: 12px;
          margin-top: 2px;
        }

        md-checkbox .md-icon {
          width: 12px;
          height: 12px;
        }

        md-checkbox .md-label {
          margin-left: 25px;
        }

        md-checkbox.md-checked .md-icon {
          background-color: #28dab6;
          width: 12px;
          height: 12px;

          &:after {
            left: 2px;
            top: -2px;
            width: 5px;
            height: 10px;
          }
        }

        .check-option {
          font-size: 14px;
          display: inline-block;
          margin-top: -1px;
        }

        .different-address {
          .add-address {
            display: inline-block;
            width: 10px;
            height: 10px;
            color: #acacac;
            line-height: 1.45;
            font-weight: bold;
            cursor: pointer;
            font-size: 20px;
            margin-right: 14px;
          }
        }

        .line-center {
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }

      .promo-code {
        .promo-code-form {
          margin-top: 20px;

          #promo_code {
            width: 158px;
            height: 35px;
            float: left;
            box-shadow: inset -0.9px 0.5px 9px 0 rgba(0, 0, 0, 0.03);
            border: solid 1.5px #dddddd;
            border-radius: 2px;

            &.error {
              border-color: #d43f00;
            }
          }

          .btn-apply {
            background: #28dab6;
            color: #fff;
            border-radius: 2px;
            padding: 0 20px;
            width: 89px;
            font-family: CerebriSans-SemiBold;
            font-size: 14px;
            height: 35px;
            display: inline-block;
            margin-left: 10px;
            padding-bottom: 4px;

            &:hover {
              background: #01b5a2;
            }
          }
        }

        .line-center {
          margin-top: 30px;
          margin-bottom: 30px;
        }

        .input-error {
          color: #d43f00;
          font-size: 12px;
          position: absolute;
          margin-top: -13px;

          .fa-icon {
            margin-right: 5px;
          }
        }
      }

      .additional-notes {
        padding-bottom: 50px;

        .additional-notes-box {
          width: 100%;
          height: 84px;
          border-radius: 0;
          resize: none;

          &:focus {
            border-color: #28dab6;
            box-shadow: none;
          }
        }

        .continue-to-payment {
          margin-top: 25px;
          padding-right: 0;
          padding-left: 0;

          .btn-continue {
            background: #28dab6;
            color: #fff;
            border-radius: 2px;
            transition: 300ms ease-in-out;
            padding: 0 25px;
            width: 100%;
            font-family: CerebriSans-SemiBold;
            font-size: 17px;
            height: 45px;
            margin-left: 0;

            &:hover {
              background: #01b5a2;
            }
          }
        }
      }

      .code-note {
        color: #262626;
        font-size: 15px;
        text-align: center;
      }
    }

    .box-left.checkout {
      width: 525px !important;

      .sub-title {
        font-size: 30px;
        color: #0c234c;
        font-family: CerebriSans-SemiBold;
        padding-bottom: 20px;
      }
    }

    .box-left.payment_info {
      .sub-title {
        font-size: 28px;
        color: #0c234c;
        font-family: CerebriSans-SemiBold;
        padding-bottom: 20px;
      }

      .confirm_title {
        color: #0c234c;
        font-size: 18px;
        font-family: CerebriSans-SemiBold;
      }

      .code-label {
        font-size: 15px;
        color: #555555;
      }

      .confirmation-code {
        .confirmation-code-form {
          margin-top: 20px;
          padding-bottom: 30px;

          #confirmation_code {
            width: 212px;
            height: 35px;
            float: left;
            box-shadow: inset -0.9px 0.5px 9px 0 rgba(0, 0, 0, 0.03);
            border: solid 1.5px #dddddd;
            border-radius: 2px;

            &.error {
              border-color: #d43f00;
            }
          }

          .regenerate {
            color: #999999;
            font-size: 13px;
            display: inline-block;
            margin-left: 10px;
            padding-bottom: 4px;
            text-decoration: underline;
            padding-top: 8px;
          }

          .sent-to {
            color: #999999;
            font-size: 11px;
            position: absolute;
            left: 16px;
            margin-top: 37px;
          }

          .input-error {
            color: #d43f00;
            font-size: 12px;
            position: absolute;
            left: 16px;
            margin-top: 37px;

            .fa-icon {
              margin-right: 5px;
            }
          }
        }
      }

      .payment-info {
        h4 {
          color: #0c234c;
          font-size: 18px;
          font-family: CerebriSans-SemiBold;
          padding-bottom: 15px;
          padding-top: 15px;
        }

        label {
          color: #555555;
          font-weight: normal;
          font-size: 16px;

          &.cvv2_label {
            margin-left: 35px;
          }
        }

        .form-control {
          height: 45px;
          border-radius: 2px;

          &:focus {
            border-color: #28dab6;
            box-shadow: none;
          }

          &.expiration_month {
            width: 220px;
          }

          &.expiration_year {
            width: 180px;
          }

          &.cvv2_cvc2 {
            width: 183px;
            float: right;
          }
        }

        .pad {
          padding-top: 5px;
        }

        .col-sm-4 {
          padding-right: 0;
          padding-left: 0;

          &.year {
            padding-left: 15px;
          }
        }

        .complete {
          clear: both;
          padding-top: 30px;
          padding-bottom: 50px;

          .btn-continue {
            width: 100%;
            margin-left: 0;
          }
        }
      }

      .input-error {
        color: #d43f00;
        font-size: 12px;
        position: absolute;
        left: 16px;

        .fa-icon {
          margin-right: 5px;
        }
      }

      .form-control.error {
        border-color: #d43f00;
      }
    }

    .box-left.securities {
      width: 660px !important;

      .btn-continue {
        width: 257px !important;
        margin-left: 5px !important;
      }

      .btn-save {
        width: 257px !important;
      }

      .action-buttons {
        padding-top: 25px !important;
      }
    }

    .box-left.success {
      .sub-title {
        color: #0c234c;
        font-size: 31px;
        font-family: CerebriSans-SemiBold;
      }

      h4 {
        font-size: 20px;
        padding-top: 12px;
      }

      p {
        padding-top: 12px;
        color: #262626;
        font-size: 16px;

        &.success3 {
          width: 525px;
        }
      }

      .next-steps {
        font-size: 20px;
        color: #262626;
        font-family: CerebriSans-SemiBold;
        padding-top: 30px;

        &.success3 {
          font-size: 18px;
        }
      }

      .step {
        .step-title {
          color: #262626;
          font-size: 16px;
          font-family: CerebriSans-SemiBold;
        }

        .info {
          font-size: 14px;
          font-weight: normal;
          padding-left: 17px;

          .documents {
            margin-top: 10px;
          }
        }

        .address {
          padding-left: 17px;
          font-size: 14px;
          padding-top: 12px;
        }

        .actions {
          padding-left: 10px;
          padding-top: 20px;

          .btn-actions {
            background: #28dab6;
            color: #fefefe;
            border-radius: 2px;
            padding: 0 20px;
            width: 166px;
            font-family: CerebriSans-SemiBold;
            font-size: 15px;
            height: 38px;
            display: inline-block;
            margin-left: 10px;
            padding-bottom: 4px;

            &:hover {
              background: #01b5a2;
            }
          }
        }

        &.last {
          padding-bottom: 60px;
        }
      }

      .btn-overview {
        background: #28dab6;
        color: #fefefe;
        border-radius: 2px;
        padding: 0 20px;
        width: 216px;
        font-family: CerebriSans-SemiBold;
        font-size: 15px;
        height: 38px;
        display: inline-block;
        padding-bottom: 4px;
        margin-top: 20px;

        &:hover {
          background: #01b5a2;
        }
      }
    }

    .box-right.success {}

    .box-right {
      width: 400px;
      background-color: #f5f5f5;
      padding: 20px 30px;
      line-height: 27px;
      margin-top: 20px;
      line-height: 1.52857143;

      h4 {
        font-size: 16px;
        font-family: CerebriSans-SemiBold;
        color: #555555;
      }

      p {
        color: #555;
        font-size: 14px;
        font-family: CerebriSans-Light;
      }

      .m40 {
        margin-top: 40px;
      }

      a {
        text-decoration: none;
        color: #888888;
      }

      .summary {
        font-size: 14px;
        color: #555555;

        .item {
          height: 30px;

          &.total {
            font-family: CerebriSans-SemiBold;
          }

          .info-icon {
            width: 9px;
            height: 9px;
            color: #a6a6a6;
            margin-left: 5px;
            margin-top: -3px;
            cursor: pointer;
          }
        }

        .item-title {
          float: left;

          .label {
            background-color: #e6e6e6;
            text-transform: uppercase;
            width: 75px;
            height: 17px;
            font-size: 12px;
            color: #999;
            padding: 2px;
            margin-left: 12px;
            font-family: CerebriSans-Semibold;
            padding: 3px 5px 1px;
          }
        }

        .item-price {
          display: inline-block;
          float: right;
        }

        .line-center {
          clear: both;
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }
    }

    .box-right.securities {
      .bold {
        font-family: CerebriSans-SemiBold;
        line-height: 1.52857143;
      }

      .info {
        font-family: CerebriSans-SemiBold;
        font-size: 12px;
        color: #acacac;
        margin-top: 30px;
      }
    }
  }

  .modal-dialog {
    width: 558px;

    .close {
      width: 20px;
      height: 20px;
      color: #acacac;
      font-weight: bold;
      right: 20px;
      margin-top: 20px;
      opacity: 1;
      cursor: pointer;
      position: absolute;
      z-index: 1000000;
    }

    .modal-content {
      width: 558px;
      height: 554px;

      .modal-body {
        padding: 40px;
        padding-top: 20px;

        &.unable-to-complete-transaction {
          text-align: center;
          padding-top: 25%;

          .error-hint {
            color: #d43f00;
            text-transform: uppercase;
            font-size: 12px;
            font-family: CerebriSans-SemiBold;
          }

          h1 {
            font-size: 22px;
            color: #0c234c;
            font-family: CerebriSans-SemiBold;
          }

          .btn-continue {
            background: #28dab6;
            color: #fff;
            border-radius: 2px;
            transition: 300ms ease-in-out;
            padding: 0 25px;
            margin-top: 20px;
            width: 230px;
            font-family: CerebriSans-SemiBold;
            font-size: 16px;
            height: 45px;

            &:hover {
              background: #01b5a2;
            }
          }
        }

        .title {
          color: #0c234c;
          font-size: 23.9px;
          font-family: CerebriSans-SemiBold;
          text-align: center;
          padding-bottom: 10px;
        }

        form {
          .form-group {
            label {
              font-weight: normal;
              color: #6d6d6d;
              font-size: 14px;
            }

            .input-error {
              color: #d43f00;
              font-size: 12px;
              position: absolute;
              margin-top: 2px;

              .fa-icon {
                margin-right: 5px;
              }
            }

            .form-control {
              height: 40px;
              border-radius: 0;
              width: 200px;

              &:focus {
                border-color: #28dab6;
                box-shadow: none;
              }
            }

            .form-control.error {
              border-color: #d43f00;
            }
          }

          .btn-ok {
            background: #28dab6;
            color: #fff;
            /*letter-spacing: 1px;*/
            border-radius: 2px;
            transition: 300ms ease-in-out;
            padding: 0 25px;
            margin-top: 15px;
            width: 100%;
            font-family: CerebriSans-SemiBold;
            font-size: 17px;
            height: 45px;

            &:hover {
              background: #01b5a2;
            }
          }

          .pad-btn {
            padding-top: 15px;
            padding-bottom: 100px;
          }

          .m10 {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .error-block {
    .content {
      margin-left: -20px;

      .error-hint {
        width: 276px;
        background-color: #d43f00;
        font-size: 12px;
        font-family: CerebriSans-SemiBold;
        color: #fff;
        padding: 7px;
        margin-top: 40px;
        text-transform: uppercase;

        .fa-icon {
          display: inline-block;
          margin-right: 6px;
          margin-left: 5px;
        }
      }

      h1 {
        font-family: CerebriSans-Bold;
        color: #d43f00;
        font-size: 28px;
      }

      p {
        font-size: 16px;
        margin-top: 20px;
        color: #555555;
        font-family: CerebriSans-Book;
      }

      ul {
        color: #555555;
        font-size: 16px;

        li {
          line-height: 35px;
        }
      }

      .btn-continue {
        width: 216px !important;
        height: 37px !important;
        font-size: 14.9px !important;
        margin-top: 40px !important;
      }
    }
  }
}

@media (max-width: 768px) {
  #headerback .navbar .transaction_title {
    font-size: 27px;
    margin-top: 35px;
  }
}