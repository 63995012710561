.how-steps-step2 {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;

    .pad {
        padding-top: 40px;
    }

    .sub {
        display: block;
        color: #959595;
        font-size: 16px;
        text-transform: uppercase;
        font-family: CerebriSans-SemiBold;
    }

    h2 {
        color: #2f2f2f;
        font-size: 30px;
        font-family: CerebriSans-SemiBold;
        margin-top: 5px;
    }

    p {
        color: #959595;
        font-size: 17px;
        padding-top: 15px;
        width: 60%;
        margin: 0 auto;
    }

    .step2 {
        margin-top: 20px;
        margin-bottom: 40px;


        .left-p1 {
            color: #959595;
            font-size: 18px;
            padding-top: 50px;
            float: right;
            margin-right: 20px;
            display: inline-block;
            padding-bottom: 40px;
            width: 82%;

            h3 {
                color: #6d6d6d;
                font-size: 20px;
                font-family: CerebriSans-SemiBold;
                margin-top: 5px;
            }

            p {
                width: 100%;
                padding-top: 0;
            }
        }

        .right-p {
            color: #959595;
            font-size: 18px;
            padding-top: 90px;
            width: 82%;
            float: right;
            margin-right: 55px;
            display: inline-block;
            padding-bottom: 40px;

            h3 {
                color: #6d6d6d;
                font-size: 20px;
                font-family: CerebriSans-SemiBold;
                margin-top: 5px;
            }

            p {
                width: 100%;
                padding-top: 0;
            }

        }

        .left-p2 {
            color: #959595;
            font-size: 18px;
            padding-top: 20px;
            width: 82%;
            float: right;
            margin-right: 20px;
            display: inline-block;
            padding-bottom: 40px;

            h3 {
                color: #6d6d6d;
                font-size: 20px;
                font-family: CerebriSans-SemiBold;
                margin-top: 5px;
            }

            p {
                width: 100%;
                padding-top: 0;
            }
        }

        img {
            width: 100%
        }
    }
}

.how-steps-step3 {
    padding-top: 20px;
    padding-bottom: 80px;
    background-color: #f8f8f8;

    .pad {
        padding-top: 40px;
    }

    .sub {
        display: block;
        color: #959595;
        font-size: 16px;
        text-transform: uppercase;
        font-family: CerebriSans-SemiBold;
    }

    h2 {
        color: #2f2f2f;
        font-size: 30px;
        font-family: CerebriSans-SemiBold;
        margin-top: 5px;
    }

    p {
        color: #959595;
        font-size: 17px;
        padding-top: 15px;
        width: 60%;
        margin: 0 auto;
    }

    .step1 {
        margin-top: 20px;

        .left-p1 {
            color: #959595;
            font-size: 18px;
            width: 82%;
            float: right;
            margin-right: 20px;
            display: inline-block;
            padding-bottom: 40px;
        }

        .right-p {
            color: #959595;
            font-size: 18px;
            padding-top: 130px;
            width: 82%;
            float: right;
            margin-right: 20px;
            display: inline-block;
            padding-bottom: 40px;
        }

        .left-p2 {
            color: #959595;
            font-size: 18px;
            padding-top: 100px;
            width: 82%;
            float: right;
            margin-right: 20px;
            display: inline-block;
            padding-bottom: 40px;
        }

        img {
            width: 100%
        }
    }
}

.get-ready {
    padding-top: 55px;
    padding-bottom: 80px;
    background-color: #334462;

    .pad {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    h2 {
        color: #fff;
        font-family: CerebriSans-SemiBold;
        font-size: 24px;
    }

    .btn-get {
        background: #28dab6;
        color: #fff;
        transition: 300ms ease-in-out;
        padding: 0 25px;
        margin-top: 15px;
        width: 50%;
        font-family: CerebriSans-SemiBold;
        font-size: 18px;
        height: 45px;
        border-radius: 2px;
        line-height: 43px;

        &:hover {
            background: #01b5a2;
        }
    }

    .dditional {
        padding-top: 25px;
        font-size: 16px;
        color: #d8dade;

        a {
            text-decoration: underline;
            color: #d8dade;
        }
    }

}