.pricing {
    padding-bottom: 70px;
    background-color: #fff;

    .pad {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .top {

        h1 {
            font-family: CerebriSans-SemiBold;
            font-size: 42px;
            color: #0c234c;
        }

        p {
            color: #2f2f2f;
            padding: 35px;
            font-size: 20px;
            /* padding-right: 50px; */
            /* padding-left: 50px; */
            width: 78%;
            /* text-align: center; */
            margin: 0 auto;
        }
    }

    .types {
        padding-top: 10px;

        .image {
            width: 43px;
            margin-left: 64px;
            float: left;
        }

        .content {
            text-align: left;
            width: calc(100% - 115px);
            float: right;
            font-size: 15px;
            margin-top: -7px;

            h4 {
                font-family: CerebriSans-SemiBold;
                font-size: 17px;
            }
        }
    }

    .price {
        padding-top: 50px;
        padding-bottom: 5px;

        h2 {
            font-family: CerebriSans-SemiBold;
            font-size: 24px;
        }
    }
}

.pricing {
    padding-left: 5%;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.questions {
    padding-top: 55px;
    padding-bottom: 55px;
    background-color: #f8f8f8;

    .pad {
        padding-bottom: 20px;
    }

    h2 {
        color: #2f2f2f;
        font-family: CerebriSans-SemiBold;
        font-size: 24px;
    }

    .btn-get {
        background: #28dab6;
        color: #fff;
        transition: 300ms ease-in-out;
        padding: 0 25px;
        margin-top: 15px;
        width: 85%;
        font-family: CerebriSans-SemiBold;
        font-size: 18px;
        height: 45px;
        border-radius: 2px;
        line-height: 43px;

        &:hover {
            background: #01b5a2;
        }
    }
}