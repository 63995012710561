.btn-login {
    background: #28dab6;
    color: #fff;
    /*letter-spacing: 1px;*/
    border-radius: 2px;
    transition: 300ms ease-in-out;
    padding: 0 25px;
    margin-top: 15px;
    width: 100%;
    font-family: CerebriSans-SemiBold;
    font-size: 17px;
    height: 45px;

    &:hover {
        background: #01b5a2;
    }
}

.idology {
    &__err-msg {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}