.confirmation {
    &__generate-btn {
        color: #999999;
        margin-top: 18;
        cursor: pointer;
        border: none;
        background: none;

        &:hover {
            text-decoration: underline;
        }
    }
}