.checkout {
    &__code-label {
        background-color: #e6e6e6;
        text-transform: uppercase;
        width: 75px;
        height: 17px;
        font-size: 12px;
        color: #262626;
        padding: 2px;
        margin-left: 12px;
        padding-left: 5px;
        padding-right: 5px;

    }

    &__item-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }
}