.account-details {
    &__input {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057 !important;
        background-color: #fff !important;
        background-clip: padding-box;
        border: 1px solid #ced4da !important;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        width: 340px !important;
        height: 45px !important;
        border-radius: 0 !important;
    }

    &__label {
        font-family: CerebriSans-Book !important;
    }

    &__update-btn {
        background: #28dab6;
        color: #fff;
        border-radius: 2px;
        transition: 300ms ease-in-out;
        padding: 0 25px;
        margin-top: 30px;
        width: 230px;
        font-family: CerebriSans-SemiBold;
        font-size: 16px;
        height: 45px;
    }
}