.bio-section {
    background-color: #fff;
    /*position: absolute;*/
    top: 128px;
    margin: 0 auto;
    /*width:100%;*/
    height: 100%;

    h4 {
        /*font-weight:bold;*/
        font-family: CerebriSans-SemiBold;
        font-size: 30px;
        color: #0c234c;
        text-align: left;
    }

    .close {
        font-size: 20px;
        padding-right: 37px;
        color: #333;
        cursor: pointer;
        opacity: 1;
    }

    .carousel-inner {


        .row {
            padding: 60px;

            img {
                width: 200px;
            }

            .txt {
                margin-top: -18px;

                ul {
                    list-style-type: none;
                    width: 120px;
                    top: 15px;
                    position: absolute;
                    left: 210px;

                    li {
                        padding: 5px;
                        cursor: pointer;
                        display: inline-block;
                        color: #9b9b9b;

                        &:hover {
                            color: #28dab6;
                        }
                    }
                }

                p {
                    width: 90%;
                    font-family: CerebriSans-Light;
                }
            }
        }
    }

    .fa {
        font-size: 1.5em;
        cursor: pointer;
    }
}

.about {
    padding-bottom: 70px;
    background-color: #fff;

    .pad {
        padding-top: 50px;
    }

    .top {
        h1 {
            font-family: CerebriSans-SemiBold;
            font-size: 42px;
            color: #0c234c;
        }

        .line {
            background-color: #28dab6;
            height: 3px;
            width: 40px;
            margin: 0 auto;
        }

        p {
            font-family: CerebriSans-Light;
            color: #2f2f2f;
            font-size: 20px;
            padding: 35px;
            width: 65%;
            /* text-align: center; */
            margin: 0 auto;
        }
    }

    .team {
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                padding: 5px;
                cursor: pointer;
                display: inline-block;
                color: #9b9b9b;

                &:hover {
                    color: #28dab6;
                }
            }
        }

        .pad {
            padding-bottom: 20px;
        }

        .team-item {
            padding-right: 0;
            padding-left: 40px;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }

    h3 {
        font-family: CerebriSans-SemiBold;
        font-size: 20px;
        color: #0c234c;
    }

    .space20 {
        margin-top: 20px;
    }

    p {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 14px;

        .bio {
            color: #28dab6;
            cursor: pointer;
            display: inline-block;
        }
    }
}

.guarantee {
    padding-top: 55px;
    background-color: #f8f8f8;

    h2 {
        font-family: CerebriSans-SemiBold;
        font-size: 24px;
        color: #0c234c;
    }

    p {
        color: #747474;
        padding: 0 15% 15px;
        font-size: 18px;
        padding-right: 15%;
        padding-left: 15%;
        font-family: CerebriSans-Light;
    }

    .pad {
        padding-bottom: 75px;
    }

    .btn-how {
        background: #f8f8f8;
        color: #28dab6;
        border: 2px solid #93ecda;
        transition: 300ms ease-in-out;
        padding: 0 25px;
        width: 200px;
        font-family: CerebriSans-SemiBold;
        font-size: 15px;
        height: 45px;
        border-radius: 2px;

        &:hover {
            border: 2px solid #28dab6;
            color: #01b5a2;
        }
    }
}