footer {
    ul {
        list-style-type: none;

        li {
            padding: 5px;

            a {
                color: #999;

                &:hover {
                    text-decoration: none;
                    color: #c8c8c8;
                    background-color: transparent;
                }
            }

        }
    }

}

.btn-create-account {
    background: orange;
    color: #fff;
    transition: 300ms ease-in-out;
    width: 100%;
    font-family: CerebriSans-SemiBold;
    font-size: 15px;
    height: 35px;
    vertical-align: middle;
    border-radius: 2px;

    &:hover {
        color: #fff;
        background: lightsalmon;
    }
}

.btn-login {
    background: #eeeeee;
    color: #9f9f9f;
    transition: 300ms ease-in-out;
    width: 100%;
    font-family: CerebriSans-SemiBold;
    font-size: 15px;
    height: 35px;
    transition: 300ms ease-in-out;
    border: 2px solid #d8d8d8;
    border-radius: 2px;

    &:hover {
        color: #9f9f9f;
        background: #fff;
    }
}

.footer-bottom {
    padding-top: 30px;

    .footer-txt {
        color: #9b9b9b;
        font-size: 12px;
        padding-left: 40px;

        a {
            color: #9b9b9b;
            text-decoration: none;
        }
    }

    .social {
        text-align: right;
        padding-right: 0;

        li {
            color: #9b9b9b;
            display: inline-block;

            &:hover {
                color: #fff;
            }
        }
    }
}