.btn-disabled {
    background: gray;
    color: #fff;
    border-radius: 2px;
    transition: 300ms ease-in-out;
    padding: 0 25px;
    margin-top: 20px;
    width: 230px;
    font-family: CerebriSans-SemiBold;
    font-size: 16px;
    height: 45px;
}